<template>
  <base-material-card icon="mdi-clipboard-text" :title="name" class="px-5 py-3">
    <div class="listing--form-container">
      <page-loading :show="loading" />
      <form @submit.prevent="submit">
        <div
          class="form--group row"
          :class="{ 'has-error': validation.hasError('selectedAgency') }"
        >
          <label class="col-12 col-lg-3" for="agencyField">
            <span>Agensi</span>
          </label>
          <div class="col-12 col-lg-6">
            <multiselect
              name="agency"
              id="agencyField"
              v-model="selectedAgency"
              :options="agencies"
              :searchable="true"
              :close-on-select="true"
              track-by="id"
              label="name"
              :disabled="!allowChangeAgency"
            />
            <span class="val-error">{{ validation.firstError('selectedAgency') }}</span>
          </div>
        </div>
        <div class="form--group row" :class="{ 'has-error': validation.hasError('name') }">
          <label class="col-12 col-lg-3" for="nameField">
            <span>Nama Kantor Agen</span>
          </label>
          <div class="col-12 col-lg-6">
            <v-text-field
              id="nameField"
              v-model="name"
              type="text"
              name="name"
              outlined
              hide-details
              :readonly="readOnly"
            />
            <span class="val-error">{{ validation.firstError('name') }}</span>
          </div>
        </div>
        <div class="form--group row" :class="{ 'has-error': validation.hasError('nib') }">
          <label class="col-12 col-lg-3" for="nibField">
            <span>Nomor Induk Berusaha</span>
          </label>
          <div class="col-12 col-lg-6">
            <v-text-field
              id="nibField"
              v-model="nib"
              type="text"
              name="nib"
              outlined
              hide-details
              :readonly="readOnly"
            />
            <span class="val-error">{{ validation.firstError('nib') }}</span>
          </div>
        </div>
        <div class="form--group row" :class="{ 'has-error': validation.hasError('logoFile') }">
          <label class="col-12 col-lg-3" for="nibField">
            <span>Gambar Logo</span>
          </label>
          <div class="col-12 col-lg-6">
            <div class="logo--preview" v-if="logoFile">
              <img :src="logoFile.url" />
              <v-btn color="error" @click="removeLogo" v-if="readOnly !== true">
                <v-icon v-text="'mdi-trash-can-outline'" />
              </v-btn>
            </div>
            <div class="logo--preview" v-else-if="logoUrl">
              <img :src="logoUrl" />
              <v-btn color="error" @click="removeLogo" v-if="readOnly !== true">
                <v-icon v-text="'mdi-trash-can-outline'" />
              </v-btn>
            </div>
            <v-btn
              type="button"
              color="primary"
              class="btn btn-primary--light btn-block"
              @click="openMediaGallery"
              v-if="readOnly !== true"
            >
              {{ $t('agentOffice.choose') }}
            </v-btn>
            <span class="val-error">{{ validation.firstError('logoFile') }}</span>
          </div>
        </div>
        <div class="d-flex align-center justify-end" v-if="readOnly !== true">
          <v-btn color="primary" class="bottom--button" type="submit">
            {{ $t('agentOffice.btn.save') }}
          </v-btn>
        </div>
      </form>
    </div>
    <modal-media-file></modal-media-file>
  </base-material-card>
</template>

<script>
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');

const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const ModalMediaFile = () => import('@/components/modals/modal-media-file');
import { mapState, mapGetters } from 'vuex';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'agent-office-form',
  mixins: [HelperMixin],
  props: ['readOnly'],
  components: {
    Multiselect,
    PageLoading,
    ModalMediaFile,
  },
  computed: {
    ...mapState({
      formType: (state) => state.agentOffice.form.formType,
      logoUrl: (state) => state.agentOffice.form.logoUrl,
      logoFileId: (state) => state.agentOffice.form.logoFileId,
      logoFile: (state) => state.agentOffice.form.logoFile,
      agencies: (state) => state.agentOffice.form.agencies,
      allowChangeAgency: (state) => state.agentOffice.form.allowChangeAgency,
    }),
    ...mapGetters({}),
    loading: {
      get() {
        return this.$store.state.agentOffice.form.loading;
      },
      set(value) {
        this.$store.commit('agentOffice/form/SET_LOADING', value);
      },
    },
    name: {
      get() {
        return this.$store.state.agentOffice.form.name;
      },
      set(value) {
        this.$store.commit('agentOffice/form/SET_NAME', value);
      },
    },
    nib: {
      get() {
        return this.$store.state.agentOffice.form.nib;
      },
      set(value) {
        this.$store.commit('agentOffice/form/SET_NIB', value);
      },
    },
    selectedAgency: {
      get() {
        return this.$store.state.agentOffice.form.selectedAgency;
      },
      set(value) {
        this.$store.commit('agentOffice/form/SET_SELECTED_AGENCY', value);
      },
    },
  },
  watch: {
    logoFile(val) {
      if (val !== null) {
        this.$store.commit('agentOffice/form/SET_LOGO_FILE_ID', val.id);
      }
    },
  },
  validators: {
    name(value) {
      return Validator.value(value).required(this.$i18n.t('errors.agentOffice.name.required'));
    },
    selectedAgency(value) {
      return Validator.value(value).required(
        this.$i18n.t('errors.agentOffice.selectedAgency.required'),
      );
    },
  },
  methods: {
    validate() {
      let validationArray = [];
      validationArray.push(this.$validate());
      return Promise.all(validationArray).then(function (results) {
        if (
          results.filter(function (result) {
            console.log('WHICH SECTION FAILED: ', result);
            return !result;
          }).length === 0
        ) {
          console.log('ALL VALID!');
          return true;
        }
        console.log('SOME INVALID!');
        return false;
      });
    },
    async submit() {
      if (await this.validate()) {
        await this.$store.dispatch('agentOffice/form/postData');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('agentOffice.save.successMsg'),
          'success',
        );
        this.$router.back();
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
    openMediaGallery() {
      this.$modal.show('modal-media-file', {
        selectedMediaId: this.logoFileId,
        commitString: 'agentOffice/form/SET_LOGO_FILE',
      });
    },
    removeLogo() {
      this.$store.commit('agentOffice/form/SET_LOGO_FILE_ID', null);
      this.$store.commit('agentOffice/form/SET_LOGO_FILE', null);
      this.$store.commit('agentOffice/form/SET_LOGO_URL', null);
    },
  },
};
</script>

<style lang="scss" scoped>
.logo--preview {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
  img {
    max-width: calc(100% - 92px);
    aspect-ratio: 1;
    object-fit: contain;
    margin-right: 12px;
  }
}
</style>
